@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900");

html {
  /* below line provides global text size control */
  /* default */
  font-size: 16px;
  /* large desktop */
  @media only screen and (max-width: 1200px) {
    font-size: calc(16px * 0.975);
  }
  /* small desktop */
  @media only screen and (max-width: 992px) {
    font-size: calc(16px * 0.85);
  }
  /* tablet */
  @media only screen and (max-width: 768px) {
    font-size: calc(16px * 0.825);
  }
  /* large mobile */
  @media only screen and (max-width: 576px) {
    font-size: calc(16px * 0.775);
  }
  /* medium mobile */
  @media only screen and (max-width: 375px) {
    font-size: calc(16px * 0.75);
  }
  /* small mobile */
  @media only screen and (max-width: 320px) {
    font-size: calc(16px * 0.65);
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: hidden; */
}
